import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => {
  let title = post.frontmatter.title;
  if (post.frontmatter.path && post.frontmatter.path.indexOf('/weeknotes/') > -1) {
    title = title.split(',')[0]
  }
  return (
    <li className="list-item py-1">
      <h3 className="h5">
        <Link to={post.frontmatter.path}>
          {title}
          {` `}
          <small className="text-muted">{post.frontmatter.date}</small>
        </Link>
      </h3>
    </li>
  )
}

export default PostLink
